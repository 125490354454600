import { ThemeProvider } from "@mui/material/styles"
import theme from "./app/theme"
import Layout from "./containers/Layouts/BaseLayout"
import "./index.css"

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Layout />
    </ThemeProvider>
  )
}

export default App
