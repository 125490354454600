import React from "react"
import {
  SelectChangeEvent,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Checkbox,
  Box,
} from "@mui/material"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { selectTransactions, setFilters } from "../../slices/transactionsSlice"
import {
  TransactionType,
  MarkedStatus,
  operators,
  transactionTypeLabels,
  EntityType,
  entityTypeLabels,
} from "../../constants/data"
import { Dayjs } from "dayjs"
import { RefreshRounded } from "@mui/icons-material"
import { StyledFormControl } from "./styles"
import DateRangeSelector from "../../components/DateRangeSelector/DateRangeSelector"
import { PrimaryButton, StyledButton } from "./StyledButton"
interface TransactionsFiltersProps {
  resetFilterHandler: () => void
  ApplyFilterHandler: () => void
}

const TransactionsFilters: React.FC<TransactionsFiltersProps> = ({
  resetFilterHandler,
  ApplyFilterHandler,
}) => {
  const {
    filters,
    isBankListLoading,
    bankList,
    paymentMethods,
    isPaymentMethodsLoading,
  } = useAppSelector(selectTransactions)
  const dispatch = useAppDispatch()

  const handleDateRangeSelection = (newState: {
    dateSelector: string
    startDate?: Dayjs | null
    endDate?: Dayjs | null
  }) => {
    dispatch(
      setFilters({
        ...filters,
        ...newState,
      }),
    )
  }

  const options = [...bankList]

  return (
    <Stack direction="column" gap={2}>
      <StyledFormControl size="small">
        <InputLabel id="select-opertator">Operator</InputLabel>
        <Select
          labelId="select-opertator"
          id="selectOperator"
          label="Operator"
          value={filters.operator}
          onChange={(e: SelectChangeEvent) =>
            dispatch(
              setFilters({
                ...filters,
                operator: e.target.value as string,
              }),
            )
          }
          disabled={filters.search === ""}
        >
          {operators.map((operator) => (
            <MenuItem value={operator?.value}>{operator?.option}</MenuItem>
          ))}
        </Select>
      </StyledFormControl>
      <DateRangeSelector
        dateSelector={filters.dateSelector}
        filterState={filters}
        updateFilterState={handleDateRangeSelection}
      />
      <StyledFormControl size="small">
        <InputLabel id="select-transaction-type">Type</InputLabel>
        <Select
          labelId="select-transaction-type"
          id="selectTransactionType"
          label="Type"
          value={filters.transactionType}
          onChange={(e: SelectChangeEvent) =>
            dispatch(
              setFilters({
                ...filters,
                transactionType: e.target.value as string,
              }),
            )
          }
        >
          <MenuItem value="all">All</MenuItem>
          {Object.values(TransactionType).map((type) => (
            <MenuItem key={type} value={type}>
              {transactionTypeLabels[type]}
            </MenuItem>
          ))}
        </Select>
      </StyledFormControl>
      <StyledFormControl size="small">
        <InputLabel id="select-entity-type">Entity</InputLabel>
        <Select
          labelId="select-entity-type"
          id="selectEntityType"
          label="Type"
          value={filters.entityType}
          onChange={(e: SelectChangeEvent) =>
            dispatch(
              setFilters({
                ...filters,
                entityType: e.target.value as string,
              }),
            )
          }
        >
          <MenuItem value="all">All</MenuItem>
          {Object.values(EntityType).map((type) => (
            <MenuItem key={type} value={type}>
              {entityTypeLabels[type]}
            </MenuItem>
          ))}
        </Select>
      </StyledFormControl>
      <StyledFormControl size="small">
        <InputLabel id="select-accounts">Account</InputLabel>
        <Select
          labelId="select-accounts"
          id="selectAccounts"
          label="Account"
          multiple
          value={filters.accountId || []}
          onChange={(e: SelectChangeEvent<string[]>) =>
            dispatch(
              setFilters({
                ...filters,
                accountId:
                  typeof e.target.value === "string"
                    ? [e.target.value]
                    : e.target.value,
              }),
            )
          }
          renderValue={(selected) => {
            const selectedAccounts = options.filter((option) =>
              selected.includes(option.id),
            )
            return selectedAccounts
              .map((account) => account.accountName)
              .join(", ")
          }}
          disabled={isBankListLoading}
        >
          {isBankListLoading ? (
            <MenuItem value="">Loading...</MenuItem>
          ) : (
            options.map((bank) => (
              <MenuItem key={bank.id} value={bank.id}>
                <Checkbox
                  checked={
                    filters.accountId && filters.accountId.includes(bank.id)
                  }
                />
                {bank.accountName}
              </MenuItem>
            ))
          )}
        </Select>
      </StyledFormControl>
      <StyledFormControl
        size="small"
        sx={{
          minWidth: "120px",
        }}
      >
        <InputLabel id="payment-method-select">Payment Method</InputLabel>
        <Select
          labelId="payment-method-select"
          id="paymentMethodSelect"
          value={filters.paymentMethod}
          label="Payment Method"
          onChange={(e: SelectChangeEvent) =>
            dispatch(
              setFilters({
                ...filters,
                paymentMethod: e.target.value as string,
              }),
            )
          }
          disabled={isPaymentMethodsLoading}
        >
          <MenuItem value="all">All</MenuItem>
          {paymentMethods.map((method) => (
            <MenuItem value={method.key}>{method.value}</MenuItem>
          ))}
        </Select>
      </StyledFormControl>
      <StyledFormControl size="small">
        <InputLabel id="marked-status-select">Status</InputLabel>
        <Select
          labelId="marked-status-select"
          id="isMarked"
          value={filters.isMarked}
          label="Status"
          onChange={(e: SelectChangeEvent) =>
            dispatch(
              setFilters({
                ...filters,
                isMarked: e.target.value as string,
              }),
            )
          }
        >
          <MenuItem value="all">All</MenuItem>
          <MenuItem value={MarkedStatus.MARKED}>Marked</MenuItem>
          <MenuItem value={MarkedStatus.UNMARKED}>Unmarked</MenuItem>
        </Select>
      </StyledFormControl>
      <Box sx={{ display: "flex", gap: "10px", flex: 1 }}>
        <StyledButton
          sx={{ width: "100%" }}
          variant="outlined"
          startIcon={<RefreshRounded fontSize="small" />}
          color="inherit"
          onClick={resetFilterHandler}
        >
          Reset
        </StyledButton>
        <PrimaryButton
          sx={{ width: "100%" }}
          variant="contained"
          onClick={ApplyFilterHandler}
        >
          Apply
        </PrimaryButton>
      </Box>
    </Stack>
  )
}

export default TransactionsFilters
