import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material"
import { ReconcileIconButton } from "./styles"
import { useEffect, useState } from "react"
import {
  resetTransactionActionSuccess,
  selectReconciliation,
} from "../../slices/reconcilationSlice"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import {
  selectTransactions,
  getProcessSchedule,
  getTransactionList,
} from "../../slices/transactionsSlice"
import { constructApiRequestParams } from "../../utils/transactionHelpers"
import { allowedOnlyBankAccountsRoles } from "../../constants/data"
import { selectLogin } from "../../slices/loginSlice"

type TransactionActionProps = Readonly<{
  id: string
  title: string
  confirmText: string
  handleTransactionRequest: any
}>

function TransactionAction({
  title,
  confirmText,
  handleTransactionRequest,
}: TransactionActionProps) {
  const { loading, transactionActionSuccess } =
    useAppSelector(selectReconciliation)
  const { role } = useAppSelector(selectLogin)
  const { filters, pagination } = useAppSelector(selectTransactions)
  const dispatch = useAppDispatch()
  const [open, setOpen] = useState(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleRefresh = () => {
    dispatch(getTransactionList(constructApiRequestParams(filters, pagination)))
    if (allowedOnlyBankAccountsRoles.includes(role)) {
      dispatch(getProcessSchedule())
    }
  }

  useEffect(() => {
    if (transactionActionSuccess) {
      handleRefresh()
      dispatch(resetTransactionActionSuccess())
    }
  }, [transactionActionSuccess, handleRefresh, dispatch])

  return (
    <>
      <ReconcileIconButton
        onClick={() => {
          setOpen(true)
        }}
      >
        {title}
      </ReconcileIconButton>
      <Dialog
        open={open}
        aria-labelledby="update-dialog-title"
        aria-describedby="update-dialog-description"
      >
        <DialogTitle id="update-dialog-title">{confirmText}</DialogTitle>
        <DialogContent
          sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}
        >
          <Button variant="outlined" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={loading}
            onClick={() => {
              if (!loading) {
                handleTransactionRequest()
              }
              setOpen(false)
            }}
          >
            Confirm
          </Button>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default TransactionAction
