import { styled } from "@mui/material"

export const Container = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  @media only screen and (max-width: 960px) {
    flex-direction: column;
    gap: 10px;
    align-items: initial;
  }
`

export const LeftSection = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media only screen and (max-width: 480px) {
    flex-direction: column;
    align-items: stretch;
    gap: 10px;
  }
`
