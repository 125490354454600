import React from "react"
import { CircularProgress, Divider, Stack, Tooltip } from "@mui/material"
import { CachedOutlined, Schedule, UploadSharp } from "@mui/icons-material"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { UserRole, allowedOnlyBankAccountsRoles } from "../../constants/data"
import { selectLogin } from "../../slices/loginSlice"
import { PrimaryButton, StyledButton } from "./StyledButton"
import { ReconciliationMessage, ReconciliationMessageText } from "./styles"
import {
  getProcessSchedule,
  getTransactionList,
  initiateProcessSchedule,
  selectTransactions,
} from "../../slices/transactionsSlice"
import { constructApiRequestParams } from "../../utils/transactionHelpers"

interface PageHeaderActionProps {
  setUploadDialogOpen: (value: React.SetStateAction<boolean>) => void
}

export const PageHeaderAction: React.FC<PageHeaderActionProps> = ({
  setUploadDialogOpen,
}) => {
  const dispatch = useAppDispatch()
  const { role } = useAppSelector(selectLogin)

  const {
    filters,
    pagination,
    isUploading,
    processScheduleData,
    isProcessScheduleLoading,
  } = useAppSelector(selectTransactions)

  const handleUploadDialogOpen = () => {
    setUploadDialogOpen(true)
  }

  const handleProcessSchedule = () => {
    dispatch(initiateProcessSchedule())
  }

  const handleRefresh = () => {
    dispatch(getTransactionList(constructApiRequestParams(filters, pagination)))
    if (allowedOnlyBankAccountsRoles.includes(role)) {
      dispatch(getProcessSchedule())
    }
  }

  return (
    <Stack spacing={1} direction="row">
      <PrimaryButton
        variant="contained"
        startIcon={<UploadSharp fontSize="small" />}
        onClick={handleUploadDialogOpen}
        disabled={
          isUploading ||
          role === UserRole.ACCOUNT ||
          role === UserRole.ALTS_SALES
        }
      >
        {isUploading ? (
          <CircularProgress size={26} color="primary" />
        ) : (
          "Upload"
        )}
      </PrimaryButton>
      {allowedOnlyBankAccountsRoles.includes(role) ? (
        <Tooltip
          title={
            processScheduleData.status
              ? processScheduleData?.message
              : "Click to process schedule"
          }
        >
          {processScheduleData.status ? (
            <ReconciliationMessage>
              Next reconciliation scheduled at
              <ReconciliationMessageText>
                {processScheduleData.nextRun}
              </ReconciliationMessageText>
            </ReconciliationMessage>
          ) : (
            <StyledButton
              variant="outlined"
              startIcon={<Schedule fontSize="small" />}
              color="inherit"
              disabled={isProcessScheduleLoading}
              onClick={handleProcessSchedule}
            >
              {isProcessScheduleLoading ? (
                <CircularProgress size={26} color="primary" />
              ) : (
                "Reschedule"
              )}
            </StyledButton>
          )}
        </Tooltip>
      ) : null}
      <Divider orientation="vertical" flexItem />
      <StyledButton
        variant="outlined"
        startIcon={<CachedOutlined fontSize="small" />}
        color="inherit"
        onClick={handleRefresh}
      >
        Refresh
      </StyledButton>
    </Stack>
  )
}
