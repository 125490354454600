// UsersTable.tsx
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { GridCellParams } from "@mui/x-data-grid"
import { GridColDef, GridRowModel, GridRowsProp } from "@mui/x-data-grid"
import dayjs from "dayjs"
import { fetchRoles, rolesType } from "../../slices/usersSlice"
import userCheck from "../../utils/userCheck"
import { updateUserDetailsService } from "../../services/userService"
import { useAppDispatch } from "../../app/hooks"
import { USERS } from "../../constants/path"
import { useNavigate } from "react-router-dom"
import Table from "../../components/Table/Table"
import { ProfileImage } from "../UserProfile/Profile"

interface UsersTableProps {
  users: any[]
  roles: rolesType[]
  isLoading: boolean
  pageInfo: any
  paginationModel: any
  setPaginationModel: React.Dispatch<React.SetStateAction<any>>
  handleRefresh: () => void
}

const UsersTable: React.FC<UsersTableProps> = ({
  users,
  isLoading,
  pageInfo,
  paginationModel,
  setPaginationModel,
}) => {
  const dispatch = useAppDispatch()
  const [rowCountState, setRowCountState] = useState(
    pageInfo?.totalRowCount || 0,
  )
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(fetchRoles())
  }, [dispatch])

  useEffect(() => {
    setRowCountState((prevRowCountState: number) =>
      pageInfo?.totalRowCount !== undefined
        ? pageInfo?.totalRowCount
        : prevRowCountState,
    )
  }, [pageInfo?.totalRowCount, setRowCountState])

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "profile",
        headerName: "DP",
        width: 60,
        editable: false,
        hideable: true,
        renderCell: (params: GridCellParams) => (
          <ProfileImage
            imageUrl={params.value as string}
            styles={{ width: 36, height: 36 }}
          />
        ),
      },
      {
        field: "firstName",
        headerName: "First Name",
        width: 150,
        editable: true,
      },
      {
        field: "lastName",
        headerName: "Last Name",
        width: 150,
        editable: true,
      },
      { field: "email", headerName: "Email", width: 250, editable: true },
      { field: "mobile", headerName: "Mobile", width: 150 },
      {
        field: "userRole",
        headerName: "Roles",
        width: 150,
      },
      {
        field: "profileImage",
        headerName: "Profile Picture",
        width: 150,
        type: "boolean",
      },
      { field: "kycImage", headerName: "KYC", width: 150, type: "boolean" },
      { field: "Created", headerName: "Created On", width: 150 },
      { field: "LastLogin", headerName: "Last login", width: 200 },
      {
        field: "status",
        headerName: "Status",
        width: 150,
      },
    ],
    [],
  )

  const rows: GridRowsProp = users.map((user) => ({
    id: user.id,
    firstName: userCheck(user.first_name || "", "Unknown"),
    lastName: userCheck(user.last_name || "", "User"),
    email: user.email,
    mobile: user.mobile,
    userRole: user?.roles[0].name ?? [],
    status: user.status.replace(/_/g, " "),
    profileImage: user?.profile_image || "",
    kycImage: user?.kyc_image || "",
    Created:
      dayjs.utc(user?.created_at).utcOffset("+05:30").format("DD/MM/YYYY") ||
      "",
    LastLogin: user?.last_login
      ? dayjs
          .utc(user?.last_login)
          .utcOffset("+05:30")
          .format("YYYY-MM-DD hh:mm:ss A")
      : "No Logins available",
    profile: user?.profile_image,
  }))

  const processRowUpdate = useCallback(
    async (newRow: GridRowModel) => {
      // Make the HTTP request to save in the backend
      await updateUserDetailsService({
        payload: {
          userId: newRow.id,
          firstName: newRow.firstName,
          lastName: newRow.lastName,
          email: newRow.email,
          ...paginationModel,
        },
      })
      return newRow
    },
    [paginationModel],
  )

  const handleRowClick = (params: GridCellParams) => {
    if (params.field !== "userRole" && params.field !== "status")
      navigate(`${USERS}/${params.row.id}/profile`, {
        state: {
          title: "User Details",
          prevPage: "Users",
          path: location.pathname,
        },
      })
  }

  return (
    <Table
      rows={rows}
      columns={columns}
      processRowUpdate={processRowUpdate}
      loading={isLoading}
      rowCount={rowCountState}
      pageSizeOptions={[10]}
      paginationModel={paginationModel}
      paginationMode="server"
      onPaginationModelChange={setPaginationModel}
      disableColumnFilter
      onCellClick={handleRowClick}
      columnVisibilityModel={{
        profile: false,
      }}
    />
  )
}

export default UsersTable
