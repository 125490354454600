import { Stack } from "@mui/material"
import PageHeader from "../../components/PageHeader/PageHeader"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { fetchUsersByName } from "../../slices/centersSlice"
import { useEffect, useState } from "react"
import {
  fetchCenterLogs,
  PaginationType,
  resetFilters,
  selectAuditLogs,
  setPagination,
  setFilters,
} from "../../slices/auditLogsSlice"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import useMediaQuery from "@mui/material/useMediaQuery"
import AuditLogsTable from "./AuditLogTable"
import { fetchRoles, fetchUsers, selectUsers } from "../../slices/usersSlice"
import AuditLogFilter from "../../components/AuditLogFilter/AuditLogFilter"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import queryString from "query-string"

interface IAuditLogsProps {
  isPartofUserProfile?: boolean
}

// Extend dayjs with the UTC plugin
dayjs.extend(utc)

const AuditLogs = ({ isPartofUserProfile = false }: IAuditLogsProps) => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const { roles } = useAppSelector(selectUsers)
  const { userId } = useParams()
  const { pageInfo, filters, pagination } = useAppSelector(selectAuditLogs)
  const [rowCountState, setRowCountState] = useState(
    pageInfo?.totalRowCount || 0,
  )

  const isMiniScreen = useMediaQuery("(max-width: 400px)")
  const isSmallScreen = useMediaQuery(
    "(min-width: 400px) and (max-width: 576px)",
  ) // For screens up to 600px wide
  const isMediumScreen = useMediaQuery(
    "(min-width: 576px) and (max-width: 1028px)",
  )
  const isXlScreen = useMediaQuery("(min-width: 1280px)")

  useEffect(() => {
    // Fetch roles
    dispatch(fetchRoles())

    // Fetch users by name
    if (!userId) dispatch(fetchUsersByName({ name: "a" }))
  }, [dispatch, userId])

  useEffect(() => {
    if (roles.length > 0) {
      const gatekeeperRoleId = roles.find((user) => user.name === "gatekeeper")

      // Fetch users based on the gatekeeper role
      dispatch(
        fetchUsers({
          role: gatekeeperRoleId?.id,
          page: 0,
          pageSize: 1000,
        }),
      )
    }
  }, [dispatch, roles])

  const constructApiRequestParams = () => {
    return {
      ...filters,
      ...pagination,
      status: filters.status,
      startDate: dayjs(filters.startDate).format("YYYY-MM-DD"),
      endDate: dayjs(filters.endDate).format("YYYY-MM-DD"),
      userId: userId || filters.userId || "",
      gatekeeper: filters.gatekeeper,
      centerId: filters.centerId !== "all" ? filters.centerId : null,
    }
  }

  useEffect(() => {
    // URL Parameters to Redux State
    const params = queryString.parse(location.search)
    if (Object.keys(params).length !== 0) {
      const updatedFilters = { ...filters, ...params }
      if (updatedFilters.status && !Array.isArray(updatedFilters.status)) {
        updatedFilters.status = [updatedFilters.status]
      }
      // URL has filter parameters
      if (!Array.isArray(params.page) && !Array.isArray(params.pageSize)) {
        const page = parseInt(params.page || "0")
        const pageSize = parseInt(params.pageSize || "10")
        dispatch(setPagination({ page, pageSize }))
      }
      dispatch(setFilters(updatedFilters))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const filteredFilterState = Object.fromEntries(
      // eslint-disable-next-line
      Object.entries(filters).filter(([_, value]) => value !== ""),
    )

    // set filters to URL for sharing purpose
    const params = queryString.stringify({
      ...filteredFilterState,
      ...pagination,
    })
    navigate({ search: params })
    dispatch(fetchCenterLogs(constructApiRequestParams()))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, filters, pagination])

  // reset filter handler
  const resetFilterHandler = () => {
    dispatch(resetFilters())
  }

  //handle refresh
  const handleRefresh = () => {
    dispatch(fetchCenterLogs(constructApiRequestParams()))
  }

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      pageInfo?.totalRowCount !== undefined
        ? pageInfo?.totalRowCount
        : prevRowCountState,
    )
  }, [pageInfo?.totalRowCount, setRowCountState])

  const setPaginationModel = (page: PaginationType) => {
    dispatch(setPagination(page))
  }

  const checkForScreenSize = () => {
    if (isPartofUserProfile) {
      if (isMiniScreen) {
        return { maxWidth: 340, width: "100%" }
      } else if (isSmallScreen) {
        return { maxWidth: 400, width: "100%" }
      } else if (isMediumScreen) {
        return { maxWidth: 550, width: "100%" }
      } else if (isXlScreen) {
        return { maxWidth: 800, width: "100%" }
      } else {
        return { maxWidth: 1000, width: "100%" }
      }
    }
    return { width: "97%" }
  }

  return (
    <>
      {!userId && <PageHeader title="Audit Logs" />}
      <Stack
        style={checkForScreenSize()}
        margin="auto"
        gap={1}
        m={isPartofUserProfile ? 0 : 2}
      >
        <AuditLogFilter
          handleRefresh={handleRefresh}
          resetFilterHandler={resetFilterHandler}
          isSmallScreen={isSmallScreen}
          isMiniScreen={isMiniScreen}
          isMediumScreen={isMediumScreen}
        />
        <AuditLogsTable
          rowCount={rowCountState}
          paginationModel={pagination}
          onPaginationModelChange={setPaginationModel}
          isSmallScreen={isSmallScreen}
        />
      </Stack>
    </>
  )
}

export default AuditLogs
