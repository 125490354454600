import axiosConfig from "../app/axiosConfig"
import {
  CENTERS_API,
  CENTER_BY_ID_API,
  CENTER_CLUSTERS_API,
  USERS_LIST_API,
} from "../constants/apis"
import {
  fetchUsersByNamePayloadType,
  addCenterPayloadType,
  centerDetailsPayloadType,
  updateCenterPayloadType,
} from "../slices/centersSlice"
import { computeRoute } from "../utils/compute-route"

export const fetchCentersService = () =>
  axiosConfig.get(CENTERS_API, {
    params: {
      limit: 1000,
    },
  })

export const addCenterService = ({ payload }: addCenterPayloadType) => {
  const formData = new FormData()
  formData.append("name", payload.centerName)
  formData.append("google_maps_url", payload.googleMapsLocation)
  formData.append("working_hours_start", payload.openTime)
  formData.append("contact_person_phone", payload.contactPersonPhone)
  formData.append("working_hours_end", payload.closeTime)
  formData.append("is_day_pass_enabled", payload.dayPassAvailable.toString())
  formData.append("day_pass_price", payload.dayPassPrice.toString())
  formData.append("description", payload.description)
  formData.append("rules", payload.rules)
  if (payload.gatekeeperIds && payload.gatekeeperIds.length) {
    payload.gatekeeperIds.forEach((id: string) => {
      formData.append("gatekeeper_ids", id)
    })
  }

  if (payload.clusterName) {
    formData.append("cluster_name", payload.clusterName)
  }

  /// Append amenities as an array
  payload.amenities.forEach((amenity) => {
    formData.append(`amenities`, amenity)
  })

  // Append images
  payload.files.forEach((image) => {
    formData.append(`images`, image)
  })

  return axiosConfig.post(CENTERS_API, formData)
}

export const updateCenterService = ({ payload }: updateCenterPayloadType) => {
  const formData = new FormData()
  formData.append("name", payload.centerName)
  formData.append("address", payload.areaName)
  formData.append("google_maps_url", payload.googleMapsLocation)
  formData.append("contact_person_phone", payload.contactPersonPhone)
  formData.append("working_hours_start", payload.openTime)
  formData.append("working_hours_end", payload.closeTime)
  formData.append("is_day_pass_enabled", payload.dayPassAvailable.toString())
  formData.append("day_pass_price", payload.dayPassPrice.toString())
  formData.append("description", payload.description)
  formData.append("rules", payload.rules)

  if (payload.gatekeeperIds && payload.gatekeeperIds.length) {
    payload.gatekeeperIds.forEach((id: string) => {
      formData.append("gatekeeper_ids", id)
    })
  }

  if (payload.clusterName) {
    formData.append("cluster_name", payload.clusterName)
  }

  // Append amenities as an array
  payload.amenities.forEach((amenity) => {
    formData.append(`amenities`, amenity)
  })

  // Append exsiting images an array
  payload.images.forEach((image) => {
    formData.append(`images`, image)
  })

  // Append new images
  payload.newImages.forEach((image) => {
    formData.append(`new_images`, image)
  })

  return axiosConfig.put(
    computeRoute(CENTER_BY_ID_API, { centerId: payload.centerId }),
    formData,
  )
}

export const fetchUsersByNameService = ({
  payload,
}: fetchUsersByNamePayloadType) =>
  axiosConfig.get(USERS_LIST_API, {
    params: {
      name: payload.name,
    },
  })

export const fetchCenterDetailsService = ({
  payload,
}: centerDetailsPayloadType) =>
  axiosConfig.get(
    computeRoute(CENTER_BY_ID_API, { centerId: payload.centerId }),
  )

export const fetchCenterClusterDetailsService = () =>
  axiosConfig.get(CENTER_CLUSTERS_API)
