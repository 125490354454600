import { Customer } from "./customer.model"
import { Invoice } from "./invoice.model"
import * as Yup from "yup"

export interface ReconciliationState {
  customers: Customer[]
  invoices: Invoice[]
  loading: boolean
  selectedCustomer: Customer | null
  submitReconciliationSuccess: boolean
  transactionActionSuccess: boolean
  invoicesFetchError: string | null
}

export interface ReconciliationDTO {
  transaction_id: string
  reconcile: {
    invoice_id: string
    invoice_number: string
    is_tds_deducted: boolean
    amount: number
    tax_amount_withheld: number
  }[]
}

export interface ReconciliationFormItem {
  invoiceId: string
  invoiceNumber: string
  isTdsDeducted: boolean
  balanceAmount: number
  amount: number | ""
  taxAmountWithheld: number
  tdsPercentage: number
  tdsAlreadyDeducted: number
}

export interface ReconciliationFormValues {
  transactionId: string
  balanceAmount: number
  customer: Customer | null
  reconciliationItems: ReconciliationFormItem[]
}

export class Reconciliation {
  static reconcilationFormValidationSchema = Yup.object().shape({
    transactionId: Yup.string().required(),
    customer: Yup.object().nullable().required("Customer is required"),
    balanceAmount: Yup.number().min(0, "Amount must be 0 or more."),
    reconciliationItems: Yup.array().of(
      Yup.object().shape({
        invoiceId: Yup.string().required("Invoice is required"),
        amount: Yup.number()
          .min(0, "Amount must be positive")
          .required("Amount paid is required")
          .test("amount-too-large", "Amount is too large", function (value) {
            return (
              value <=
              this.parent.balanceAmount -
                this.parent.taxAmountWithheld -
                this.parent.tdsAlreadyDeducted
            )
          }),
        isTdsDeducted: Yup.boolean(),
        taxAmountWithheld: Yup.number(),
      }),
    ),
  })

  static createReconciliationDTO(
    formValues: ReconciliationFormValues,
  ): ReconciliationDTO {
    return {
      transaction_id: formValues.transactionId,
      reconcile: formValues.reconciliationItems.map((item) => ({
        invoice_id: item.invoiceId,
        invoice_number: item.invoiceNumber,
        is_tds_deducted: item.isTdsDeducted,
        amount: item.amount == "" ? 0 : item.amount,
        tax_amount_withheld: item.isTdsDeducted ? item.taxAmountWithheld : 0,
      })),
    }
  }
}
