export const BASE_API = import.meta.env.VITE_BACKEND_URL
export const INFINITY_BASE_API_URL = import.meta.env.VITE_INFINITY_BASE_API_URL
export const BASE_API_V1 = `${BASE_API}/api/v1`

export const USERS = `/users`
export const USERS_LIST_API = `${USERS}/list`
export const USER_LOGIN_API = `${USERS}/login/dashboard`
export const USER_INVITE_API = `${USERS}/invite`
export const USERS_REFRESH_TOKEN_API = `${USERS}/refresh_token`
export const USER_DETAILS_BY_ID_API = `${USERS}/:userId`
export const USER_BY_ID_KYC_API = `${USER_DETAILS_BY_ID_API}/reset-kyc`
export const USER_BY_ID_PROFILE_API = `${USER_DETAILS_BY_ID_API}/profile-picture`
export const USER_ROLE_UPDATE_BY_ID_API = `${USER_DETAILS_BY_ID_API}/roles`
export const USER_ROLE_DELETE_BY_ID_API = `${USER_ROLE_UPDATE_BY_ID_API}/:roleId`

export const CENTERS_API = `/centers`
export const CENTER_BY_ID_API = `${CENTERS_API}/:centerId`
export const CENTER_VERIFICATION_LOGS_API = `/admission/verification_log`
export const CENTER_CLUSTERS_API = `${INFINITY_BASE_API_URL}/public/clusters`

export const MEETING_ROOMS = `/meeting_rooms`
export const MEETING_ROOMS_BY_CENTER_ID_API = `${CENTER_BY_ID_API}${MEETING_ROOMS}`
export const MEETING_ROOM_UPDATE_BY_CENTER_ID_AND_MEETING_ROOM_ID_API = `${MEETING_ROOMS_BY_CENTER_ID_API}/:meetingRoomId`

export const BOOKINGS = `/bookings`
export const DAYPASS = `/day_pass`
export const BOOKINGS_API = `${BOOKINGS}${DAYPASS}`
export const BOOKINGS_API_BY_ID = `${BOOKINGS}${DAYPASS}/:bookingId`
export const BOOKINGS_UPDATE_ORDER_DETAILS = `${BOOKINGS}${DAYPASS}/:bookingId/update-order-details`
export const BOOKINGS_UPDATE_ORDER_MARK_ACTIVE = `${BOOKINGS}${DAYPASS}/:bookingId/mark-active`
export const BOOKINGS_DAYPASS_API = `${BOOKINGS_API}/book_for_new_user`
export const BOOKINGS_MEETING_ROOMS_API = `${BOOKINGS}${MEETING_ROOMS}`
export const BOOKINGS_MEETING_ROOMS_BY_ROOM_ID_API = `${BOOKINGS}/meeting_room`
export const BOOKINGS_MEETING_ROOMS_BY_ID_FOR_NEW_USER_API = `${BOOKINGS_MEETING_ROOMS_API}/book_for_new_user`
export const BOOKINGS_MEETING_ROOMS_BY_BOOKING_ID_API = `${BOOKINGS_MEETING_ROOMS_API}/:bookingId`
export const BOOKINGS_API_BY_ID_MODIFY_CENTER = `${BOOKINGS}${DAYPASS}/:bookingId/modify-center`
export const BOOKINGS_API_BY_ID_MODIFY_MOBILE = `${BOOKINGS}${DAYPASS}/:bookingId/modify-user`

export const ROLES_API = "/system/roles/list"

export const RECONCILIATION = "/reconciliation"
export const TRANSACTION_LIST_API = `${RECONCILIATION}/transactions`
export const UN_MATCH_TRANSACTION_API = (transactionId: string) =>
  `${TRANSACTION_LIST_API}/${transactionId}/unmatch`
export const UN_CATEGORISE_TRANSACTION_API = (transactionId: string) =>
  `${TRANSACTION_LIST_API}/${transactionId}/uncategorise`
export const TRANSACTION_UPLOAD_API = `${RECONCILIATION}/upload`
export const TRANSACTION_DOWNLOAD_API = `${RECONCILIATION}/transactions/download`
export const INVOICE_DETAILS_API = `${RECONCILIATION}/invoice`
export const INVOICE_ENQUEUE = `${RECONCILIATION}/enqueue`
export const TRANSACTION_BANKS_API = `${RECONCILIATION}/accounts`
export const TRANSACTION_PAYMENT_METHODS_API = `${RECONCILIATION}/payment_methods`
export const BANK_ACCOUNT_API = `${RECONCILIATION}/accounts`
export const BANK_ACCOUNT_LIST_API = `${RECONCILIATION}/bank_account_list`
export const BANK_ACCOUNT_BY_ID_API = `${RECONCILIATION}/bank_account/:accountId`
export const DEACTIVATE_BANK_ACCOUNT_API = `${RECONCILIATION}/deactivate_bank_account/:accountId`
export const PAYMENT_MODE_API = `${RECONCILIATION}/payment_modes`
export const BANK_NAME_LIST_API = `${RECONCILIATION}/bank_name_list`
export const PROCESS_SCHEDULE_API = `${RECONCILIATION}/is_process_scheduled`
export const INITIATE_PROCESS_SCHEDULE_API = `${RECONCILIATION}/schedule`
export const CUSTOMER_SEARCH_FOR_RECONCILIATION = `${RECONCILIATION}/customer_list`
export const CUSTOMER_PENDING_INVOICES = `${RECONCILIATION}/invoices/:customerId`
