import dayjs from "dayjs"
import {
  FilterType,
  getTransactionListPayloadType,
} from "../slices/transactionsSlice"
import { MarkedStatus, TransactionType } from "../constants/data"

export const constructApiRequestParams = (
  filters: FilterType,
  pagination: any,
) => {
  const requestParams = {
    ...filters,
    ...pagination,
    accountId: filters.accountId,
    search: filters.search,
    amount: Number(filters.amount),
    startDate: dayjs(filters.startDate).format("YYYY-MM-DD"),
    endDate: dayjs(filters.endDate).format("YYYY-MM-DD"),
  }
  return requestParams
}

export const filterService = ({ payload }: getTransactionListPayloadType) => {
  return {
    page_no: payload.page + 1,
    page_size: payload.pageSize,
    ...(payload.isMarked &&
      payload.isMarked !== "all" && { is_marked: payload.isMarked }),
    start_date: payload.startDate,
    ...(payload.operator !== "" && { operator: payload.operator }),
    end_date: payload.endDate,
    ...(payload.accountId &&
      payload.accountId !== "all" && { bank_account_id: payload.accountId }),
    ...(payload.search !== "" && { search: payload.search }),
    ...(payload.amount !== "" && { amount: Number(payload.amount) }),
    ...(payload.transactionType &&
      payload.transactionType !== "all" && {
        transaction_type: payload.transactionType,
      }),
    ...(payload.entityType &&
      payload.entityType !== "all" && {
        entity: payload.entityType,
      }),
    ...(payload.sortBy !== "none" && {
      sort_by: payload.sortBy,
      order_by: payload.orderBy,
    }),
    ...(payload.paymentMethod &&
      payload.paymentMethod !== "all" && {
        payment_method: payload.paymentMethod,
      }),
  }
}

export const titleCase = (str: string) => {
  return str
    ?.toLowerCase()
    .split(" ")
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1)
    })
    .join(" ")
}

interface Invoice {
  invoiceNumber: string
}

export function hasDuplicateInvoiceNumber(
  invoices: Invoice[] | undefined,
): boolean {
  if (invoices === undefined) return false

  const invoiceNumbers: Record<string, boolean> = {}

  for (const invoice of invoices) {
    const { invoiceNumber } = invoice

    if (invoiceNumbers[invoiceNumber]) {
      return true
    } else {
      invoiceNumbers[invoiceNumber] = true
    }
  }

  return false
}

export function getTransactionTypeDisplayValue(data: string): string {
  if (data === TransactionType.CREDIT) {
    return "Credit"
  } else if (data === TransactionType.DEBIT) {
    return "Debit"
  }
  return "--"
}

export function getTransactionDateDisplayValue(date: string): string {
  return dayjs.utc(date).utcOffset("+05:30").format("DD/MM/YYYY HH:mm:ss")
}

export function getMarkedStatus(
  isMarked: boolean,
  isReconciled: boolean,
): MarkedStatus {
  if (isMarked) {
    return MarkedStatus.MARKED
  } else if (!isMarked && isReconciled) {
    return MarkedStatus.PENDING
  } else {
    return MarkedStatus.UNMARKED
  }
}

export function shouldShowReconcileButton({
  isMarked,
  isReconciled,
  type,
  isSynced,
}: {
  isMarked: boolean
  isReconciled: boolean
  type: string
  isSynced: boolean
}) {
  if (
    isMarked ||
    isReconciled ||
    type !== TransactionType.CREDIT ||
    !isSynced
  ) {
    return false
  }
  return true
}
