import { Chip, Typography, styled } from "@mui/material"
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard"

export const PersonName = styled(Typography)`
  width: max-content;
  color: #8d8d8d;
  font-weight: ${({ theme }) => theme?.typography?.fontWeightLight || 300};
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`
export const AllocatedDate = styled(Typography)`
  font-size: 14px;
  font-weight: ${({ theme }) => theme?.typography?.fontWeightMedium || 500};
`
export const BookingId = styled(Typography)`
  font-size: 10px;
  font-style: italic;
  color: #8d8d8d;
`

export const GiftDayPassIcon = styled(CardGiftcardIcon)`
  font-size: 12px;
  color: #8d8d8d;
`

export const PaymentStatusChip = styled(Chip)`
  font-size: 12px;
  color: ${({ theme }) => theme?.palette.common.white};
  /* height: 18px; */
`

export const GSTChip = styled(Chip)`
  height: 20px;
  background: black;
  font-weight: 900;
  font-size: 8px;
  margin-inline: 4px;
`
