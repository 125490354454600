import React from "react"
import { Container, LeftSection } from "./styled"
import { Divider } from "@mui/material"

export function HeaderGrid({
  rightChildren,
  leftChildren,
}: {
  rightChildren?: JSX.Element
  leftChildren?: JSX.Element | JSX.Element[]
}): JSX.Element {
  return (
    <Container>
      {rightChildren}
      <LeftSection>
        {Array.isArray(leftChildren)
          ? leftChildren.map((child, index) => (
              <React.Fragment key={index}>
                {index > 0 && (
                  <Divider
                    orientation="vertical"
                    style={{ margin: "0px 16px" }}
                    flexItem
                  />
                )}
                {child}
              </React.Fragment>
            ))
          : leftChildren}
      </LeftSection>
    </Container>
  )
}
