import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import { useAppDispatch } from "../../app/hooks"
import { FormControl, OutlinedInput } from "@mui/material"
import { useState } from "react"
import { modifyMobile } from "../../slices/bookingHistorySlice"

export default function ChangeMobileModal({
  open,
  handleClose,
  bookingId,
}: {
  open: boolean
  handleClose: () => void
  bookingId: string
}) {
  const dispatch = useAppDispatch()
  const [mobile, setMobile] = useState("")

  const handleKeyDown = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    if (["e", "E", "+", "-"].includes(evt.key)) {
      evt.preventDefault()
    }
    if (evt.key === "Enter") {
      dispatch(modifyMobile({ bookingId, mobile }))
      handleClose()
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\D/g, "")
    if (value.length <= 10) {
      setMobile(value)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="xs"
      keepMounted={false}
    >
      <DialogTitle>Update Mobile Number</DialogTitle>
      <DialogContent>
        <FormControl variant="outlined" fullWidth>
          <OutlinedInput
            type="tel"
            placeholder="9999 9999 99"
            autoComplete="off"
            value={mobile}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            inputProps={{ maxLength: 10 }}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined" color="secondary">
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            dispatch(modifyMobile({ bookingId, mobile }))
            handleClose()
          }}
          disabled={mobile.length !== 10} // Disable the submit button if length is not 10
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}
