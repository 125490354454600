/* eslint-disable react-refresh/only-export-components */
import axiosConfig from "../app/axiosConfig"
import {
  ROLES_API,
  USERS_LIST_API,
  USER_INVITE_API,
  USER_DETAILS_BY_ID_API,
  USER_ROLE_UPDATE_BY_ID_API,
  USER_ROLE_DELETE_BY_ID_API,
  USER_BY_ID_KYC_API,
} from "../constants/apis"
import {
  fetchUserPayloadType,
  inviteUserPayloadType,
  updateUserDetailsPayloadType,
  updateUserRolePayloadType,
  deleteUserRolePayloadType,
} from "../slices/usersSlice"
import { computeRoute } from "../utils/compute-route"

export const fetchUsersService = ({ payload }: fetchUserPayloadType) => {
  return axiosConfig.get(USERS_LIST_API, {
    params: {
      pageSize: payload.pageSize,
      page: payload.page + 1,
      email: payload.email,
      last_name: payload.lastName,
      name: payload.name || payload.firstName,
      mobile: payload.mobile,
      ...(Array.isArray(payload.status) ? { status: payload.status } : {}),
      role_id: payload.role,
      limit: payload.pageSize,
    },
  })
}

export const fetchRolesService = () => axiosConfig.get(ROLES_API)

export const inviteUserService = ({ payload }: inviteUserPayloadType) =>
  axiosConfig.post(USER_INVITE_API, {
    country_code: "+91",
    mobile: payload.mobileNo,
    email: payload.email,
    first_name: payload.firstName,
    last_name: payload.lastName,
    roles: payload.roleId ? [`${payload.roleId}`] : [],
  })

export const updateUserDetailsService = ({
  payload,
}: updateUserDetailsPayloadType) => {
  const requestData: { [key: string]: string } = {}

  if (payload.email) {
    requestData.email = payload.email
  }

  if (payload.firstName) {
    requestData.first_name = payload.firstName
  }

  if (payload.lastName) {
    requestData.last_name = payload.lastName
  }

  if (payload.updatedStatus) {
    requestData.status = payload.updatedStatus
  }

  return axiosConfig.put(
    computeRoute(USER_DETAILS_BY_ID_API, { userId: payload.userId }),
    requestData,
  )
}

export const ResetKYCService = (userId: string) => {
  return axiosConfig.post(computeRoute(USER_BY_ID_KYC_API, { userId }))
}

export const updateUserRoleService = ({ payload }: updateUserRolePayloadType) =>
  axiosConfig.post(
    computeRoute(USER_ROLE_UPDATE_BY_ID_API, { userId: payload.userId }),
    {
      roles: [payload.roleId],
    },
  )

export const deleteUserRoleService = ({ payload }: deleteUserRolePayloadType) =>
  axiosConfig.delete(
    computeRoute(USER_ROLE_DELETE_BY_ID_API, {
      ...payload,
    }),
  )
