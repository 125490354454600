import React from "react"
import {
  FormControl,
  Tooltip,
  Button,
  Select,
  MenuItem,
  InputLabel,
  Stack,
  SelectChangeEvent,
  Card,
} from "@mui/material"
import RefreshRoundedIcon from "@mui/icons-material/RefreshRounded"
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined"
import { VerificationLogStatus } from "../../constants/data"
import CommonAutocomplete from "../CommonAutocomplete/CommonAutocomplete"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { fetchUsersByName, selectCenters } from "../../slices/centersSlice"
import { selectUsers } from "../../slices/usersSlice"
import DateRangeSelector from "../DateRangeSelector/DateRangeSelector"
import { useParams } from "react-router-dom"
import { selectAuditLogs, setFilters } from "../../slices/auditLogsSlice"
import { Dayjs } from "dayjs"

interface AuditLogFilterProps {
  handleRefresh: () => void
  resetFilterHandler: () => void
  isSmallScreen: boolean
  isMediumScreen: boolean
  isMiniScreen?: boolean
}

const AuditLogFilter: React.FC<AuditLogFilterProps> = ({
  handleRefresh,
  resetFilterHandler,
  isSmallScreen,
  isMiniScreen,
  isMediumScreen,
}) => {
  const dispatch = useAppDispatch()
  const { centers, isCenterLoading, users } = useAppSelector(selectCenters)
  const { users: gateKeeper, isLoading } = useAppSelector(selectUsers)
  const { filters } = useAppSelector(selectAuditLogs)

  const { userId } = useParams()

  const options = [{ name: "All", id: "all" }, ...centers]

  //handle log status change
  const handleStatusChange = (
    event: SelectChangeEvent<typeof filters.status>,
  ) => {
    const {
      target: { value },
    } = event
    dispatch(
      setFilters({
        ...filters,
        status: typeof value === "string" ? value.split(",") : value,
      }),
    )
  }

  const handleDateRangeSelection = (newState: {
    dateSelector: string
    startDate?: Dayjs | null
    endDate?: Dayjs | null
  }) => {
    dispatch(
      setFilters({
        ...filters,
        ...newState,
      }),
    )
  }

  return (
    <Card
      elevation={2}
      sx={{
        padding: 2,
        marginBottom: "10px",
        backgroundColor: "#FFFFFF",
        display: "grid",
        columnGap: 2,
        rowGap: 2,
        gridTemplateColumns:
          isSmallScreen || isMiniScreen
            ? "1fr"
            : isMediumScreen
            ? "repeat(3, 9fr)"
            : "repeat(4, 9fr)",
      }}
    >
      {!userId && (
        <CommonAutocomplete
          options={users}
          getOptionLabel={(option) =>
            option ? `${option.first_name} ${option.last_name}` : ``
          }
          value={users.find((user) => user.id === filters.userId) || null}
          onChange={(newValue) => {
            dispatch(
              setFilters({
                ...filters,
                userId: Array.isArray(newValue) ? "" : newValue?.id,
              }),
            )
          }}
          placeholder="type a name to see options"
          label="User"
          fetchOptionsDebounced={(inputValue) => {
            dispatch(fetchUsersByName({ name: inputValue }))
          }}
        />
      )}
      <FormControl
        style={{
          width: "100%",
          alignSelf: isSmallScreen || isMiniScreen ? "center" : "end",
        }}
        size="small"
      >
        <InputLabel id="select-center">Center</InputLabel>
        <Select
          labelId="select-center"
          id="centerId"
          label="Center"
          value={filters.centerId || ""}
          onChange={(e: SelectChangeEvent) =>
            dispatch(
              setFilters({
                ...filters,
                centerId: e.target.value as string,
              }),
            )
          }
          disabled={isCenterLoading}
        >
          {isCenterLoading ? (
            <MenuItem value="">Loading...</MenuItem>
          ) : (
            options.map((center) => (
              <MenuItem key={center.id} value={center.id}>
                {center.name}
              </MenuItem>
            ))
          )}
        </Select>
      </FormControl>
      <FormControl
        style={{
          width: "100%",
          alignSelf: isSmallScreen || isMiniScreen ? "center" : "end",
        }}
        size="small"
      >
        <InputLabel id="select-gatekeeper">Gatekeeper</InputLabel>
        <Select
          labelId="select-gatekeeper"
          id="gatekeeper"
          label="Gatekeeper"
          value={filters.gatekeeper || "none"}
          onChange={(e: SelectChangeEvent) =>
            dispatch(
              setFilters({
                ...filters,
                gatekeeper: e.target.value as string,
              }),
            )
          }
        >
          <MenuItem value={"none"}>None</MenuItem>
          {isLoading ? (
            <MenuItem value="">Loading...</MenuItem>
          ) : (
            gateKeeper.map((user) => (
              <MenuItem key={user.id} value={user.id}>
                {user.first_name || user.last_name
                  ? `${user.first_name || ""} ${user.last_name || ""}`
                  : "Unknown User"}
              </MenuItem>
            ))
          )}
        </Select>
      </FormControl>
      <FormControl
        style={{
          width: "100%",
          alignSelf: isSmallScreen || isMiniScreen ? "center" : "end",
        }}
        size="small"
      >
        <InputLabel id="log-status-type">Verification Log Status</InputLabel>
        <Select
          labelId="log-status-type"
          id="status"
          multiple
          value={filters.status}
          label="Verification Log Status"
          onChange={handleStatusChange}
        >
          {Object.values(VerificationLogStatus).map((status) => (
            <MenuItem key={status} value={status}>
              {status.charAt(0) + status.slice(1).toLowerCase()}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <DateRangeSelector
        dateSelector={filters.dateSelector}
        filterState={filters}
        updateFilterState={handleDateRangeSelection}
      />

      <Stack
        direction="row"
        gap={2}
        marginTop={"10px"}
        alignItems="center"
        justifyContent={isSmallScreen || isMiniScreen ? "center" : "flex-end"}
        style={{
          gridColumn:
            isSmallScreen || isMiniScreen
              ? 1
              : userId
              ? "4"
              : isMediumScreen
              ? "3"
              : "4",
        }}
      >
        <Tooltip title="Refresh">
          <Button
            variant="outlined"
            startIcon={!userId && <RefreshRoundedIcon />}
            onClick={handleRefresh}
            style={{ overflow: "hidden" }}
          >
            Refresh
          </Button>
        </Tooltip>
        <Tooltip title="Reset Filter" arrow>
          <Button
            variant="outlined"
            startIcon={!userId && <CachedOutlinedIcon />}
            onClick={resetFilterHandler}
            style={{
              overflow: "hidden",
            }}
          >
            Reset
          </Button>
        </Tooltip>
      </Stack>
    </Card>
  )
}

export default AuditLogFilter
