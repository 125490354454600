import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { Search, FilterAltRounded } from "@mui/icons-material"
import {
  getBankList,
  getPaymentMethods,
  getProcessSchedule,
  getTransactionList,
  PaginationType,
  resetFilters,
  selectSortModel,
  selectTransactions,
  setFilterCount,
  setFilters,
  setFiltersFromURL,
  setPagination,
} from "../../slices/transactionsSlice"
import PageHeader from "../../components/PageHeader/PageHeader"
import { Box, Button, InputAdornment, Stack, TextField } from "@mui/material"
import { TransactionTable } from "./TransactionTable"
import UploadPopup from "./UploadPopup"
import TransactionsFilters from "./TransactionsFilter"
import { useLocation, useNavigate } from "react-router-dom"
import queryString from "query-string"
import { constructApiRequestParams } from "../../utils/transactionHelpers"
import { allowedOnlyBankAccountsRoles } from "../../constants/data"
import { selectLogin } from "../../slices/loginSlice"
import { GridSortItem, GridSortModel } from "@mui/x-data-grid"
import { ROOT } from "../../constants/path"
import {
  ContentWrapper,
  StyledCircle,
  StyledFilterDrawer,
  TransactionsContainer,
} from "./styles"
import { HeaderGrid } from "../../components/HeaderGrid"
import { PageHeaderAction } from "./PageHeaderAction"
import { StyledButton } from "./StyledButton"
import CloseIcon from "@mui/icons-material/Close"

export const Transactions = () => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const {
    transactionList,
    pageInfo,
    isLoading,
    filters,
    bankList,
    pagination,
    filterCount,
  } = useAppSelector(selectTransactions)
  const { role } = useAppSelector(selectLogin)
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false)
  const [filterOpen, setFilterOpen] = useState(false)
  const drawerWidth = 280

  useEffect(() => {
    dispatch(getBankList())
    dispatch(getPaymentMethods())
    // URL Parameters to Redux State
    const params = queryString.parse(location.search)

    if (Object.keys(params).length !== 0) {
      // URL has filter parameters
      if (!Array.isArray(params.page) && !Array.isArray(params.pageSize)) {
        const page = parseInt(params.page || "0")
        const pageSize = parseInt(params.pageSize || "10")
        dispatch(setPagination({ page, pageSize }))
      }
      const updatedFilters = { ...filters, ...params }
      dispatch(setFiltersFromURL(updatedFilters))
      dispatch(setFilterCount())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const accountId = bankList
      .filter((bank) => bank.accountName !== "Razorpay")
      .map((bank) => bank.id)

    dispatch(
      setFilters({
        ...filters,
        accountId,
      }),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankList])

  const fetchTransaction = () => {
    dispatch(setFilterCount())
    const params = queryString.stringify({ ...filters, ...pagination })
    navigate({ search: params })
    dispatch(getTransactionList(constructApiRequestParams(filters, pagination)))
  }

  useEffect(() => {
    // set filters to URL for sharing purpose
    fetchTransaction()
    if (allowedOnlyBankAccountsRoles.includes(role)) {
      dispatch(getProcessSchedule())
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, pagination])

  // reset filter handler
  const resetFilterHandler = () => {
    dispatch(resetFilters())
    setFilterOpen(false)
  }

  const handleSetUploadDialogClose = () => {
    setUploadDialogOpen(false)
  }

  const setPaginationModel = (page: PaginationType) => {
    dispatch(setPagination(page))
  }

  const sortModel = useAppSelector(selectSortModel)

  const handleSortModelChange = (newSortModel: GridSortModel) => {
    if (!newSortModel.length) {
      dispatch(setFilters({ ...filters, sortBy: "none" }))
    } else {
      dispatch(
        setFilters({
          ...filters,
          sortBy: newSortModel[0].field,
          orderBy: newSortModel[0].sort,
        }),
      )
    }
  }

  const handleDrawerClose = () => {
    fetchTransaction()
    setFilterOpen(false)
  }

  const ApplyFilterHandler = () => {
    fetchTransaction()
    setFilterOpen(false)
  }

  useEffect(() => {
    fetchTransaction()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.search, filters.amount])

  return (
    <>
      <PageHeader
        title="Transactions"
        breadcrums={[{ title: "Home", path: ROOT }, { title: "Transactions" }]}
        actions={<PageHeaderAction setUploadDialogOpen={setUploadDialogOpen} />}
      />
      <TransactionsContainer sx={{ display: "flex", flexDirection: "row" }}>
        <ContentWrapper isOpen={filterOpen} drawerWidth={drawerWidth}>
          <Stack spacing={2}>
            <HeaderGrid
              leftChildren={[
                <TextField
                  size="small"
                  variant="outlined"
                  label="Search by Description"
                  placeholder="Search by Description"
                  value={filters.search}
                  onChange={(e) => {
                    dispatch(setFilters({ ...filters, search: e.target.value }))
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />,
                <TextField
                  size="small"
                  variant="outlined"
                  label="Search by Amount"
                  placeholder="Search by Amount"
                  type="tel"
                  value={filters.amount}
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^0-9]/g, "")
                    dispatch(setFilters({ ...filters, amount: value }))
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />,
                <StyledButton
                  variant="outlined"
                  startIcon={<FilterAltRounded fontSize="small" />}
                  endIcon={
                    Boolean(filterCount) && (
                      <StyledCircle>{filterCount}</StyledCircle>
                    )
                  }
                  color="inherit"
                  onClick={() => setFilterOpen(!filterOpen)}
                >
                  Filters
                </StyledButton>,
              ].filter((child) => Boolean(child))}
            />

            <TransactionTable
              transactions={transactionList}
              isLoading={isLoading}
              pageInfo={pageInfo}
              paginationModel={pagination}
              setPaginationModel={setPaginationModel}
              sortModel={sortModel as GridSortItem[]}
              onSortModelChange={handleSortModelChange}
            />

            {uploadDialogOpen && (
              <UploadPopup
                open={uploadDialogOpen}
                onClose={handleSetUploadDialogClose}
                bankList={bankList}
              />
            )}
          </Stack>
        </ContentWrapper>
        <StyledFilterDrawer
          variant={filterOpen ? "persistent" : "temporary"}
          anchor="right"
          open={filterOpen}
          sx={{
            flexShrink: 0,
          }}
        >
          <Box sx={{ flexGrow: 1, p: 2 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                mb: 2,
              }}
            >
              <Button color="inherit" onClick={handleDrawerClose}>
                <CloseIcon />
              </Button>
            </Box>
            <TransactionsFilters
              resetFilterHandler={resetFilterHandler}
              ApplyFilterHandler={ApplyFilterHandler}
            />
          </Box>
        </StyledFilterDrawer>
      </TransactionsContainer>
    </>
  )
}
