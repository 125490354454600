import { createSlice } from "@reduxjs/toolkit"
import { RootState } from "../app/store"
import {
  Transaction,
  Bank,
  ProcessSchedule,
} from "../models/transactions.model"
import dayjs, { Dayjs } from "dayjs"

export type FilterType = {
  dateSelector: string
  startDate: Dayjs | null
  endDate: Dayjs | null
  search: string
  amount: string
  isMarked: string
  accountId: string[]
  sortBy: string
  orderBy: string
  transactionType: string
  entityType: string
  paymentMethod: string
  operator: string
}

export type PaginationType = {
  page: number
  pageSize: number
}

export type reconcileType = {
  amount: number
  invoiceNumber: string
  tdsDeducted: boolean
  tdsAmountWithHeld: number
  tdsPercentage: number
  invoiceId: string
}[]

type TransactionsState = {
  transactionList: Transaction[]
  bankList: Bank[]
  isLoading: boolean
  pageInfo: {
    totalRowCount: number
  }
  pagination: PaginationType
  isUploading: boolean
  isDownloading: boolean
  isBankListLoading: boolean
  paymentMethods: { key: string; value: string }[]
  isPaymentMethodsLoading: boolean
  filters: FilterType
  processScheduleData: ProcessSchedule
  isProcessScheduleLoading: boolean
  filterCount: number
}

export type getTransactionListPayloadType = {
  payload: {
    page: number
    pageSize: number
    startDate?: string
    endDate?: string
    search: string
    amount: string
    accountId: string
    isMarked: string
    sortBy: string
    orderBy: string
    transactionType: string
    entityType: string
    paymentMethod: string
    operator: string
  }
}

export type uploadCSVFilePayloadType = {
  payload: {
    file: File
    accountId: string
  }
}

const initialProcessScheduleState = {
  message: "",
  status: false,
  nextRun: "",
  scheduleStatus: "",
  id: "",
  name: "",
}

export const initialFilterState = {
  dateSelector: "month",
  startDate: dayjs().startOf("month"),
  endDate: dayjs(),
  search: "",
  amount: "",
  accountId: [""],
  isMarked: "all",
  sortBy: "date",
  orderBy: "desc",
  transactionType: "all",
  entityType: "all",
  paymentMethod: "all",
  operator: "",
}

export const initialPaginationState = {
  page: 0,
  pageSize: 10,
}

export const transactionsInitialState: TransactionsState = {
  transactionList: [],
  isLoading: false,
  pagination: initialPaginationState,
  pageInfo: {
    totalRowCount: 0,
  },
  isUploading: false,
  isDownloading: false,
  bankList: [],
  isBankListLoading: false,
  paymentMethods: [],
  isPaymentMethodsLoading: false,
  filters: initialFilterState,
  processScheduleData: initialProcessScheduleState,
  isProcessScheduleLoading: false,
  filterCount: 0,
}

export const transactionsSlice = createSlice({
  name: "transactions",
  initialState: transactionsInitialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getTransactionList: (state, action: getTransactionListPayloadType) => {
      state.isLoading = true
    },
    getTransactionListSuccess: (
      state,
      {
        payload,
      }: { payload: { transactionList: Transaction[]; totalRowCount: number } },
    ) => {
      state.transactionList = payload.transactionList
      state.pageInfo.totalRowCount = payload.totalRowCount
      state.isLoading = false
    },
    getTransactionListFailed: (state) => {
      state.isLoading = false
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    uploadCSVFile: (state, action: uploadCSVFilePayloadType) => {
      state.isUploading = true
    },
    uploadCSVFileSuccess: (state) => {
      state.isUploading = false
    },
    uploadCSVFileFailed: (state) => {
      state.isUploading = false
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    downloadCSVFile: (state, action: getTransactionListPayloadType) => {
      state.isDownloading = true
    },
    downloadCSVFileSuccess: (state) => {
      state.isDownloading = false
    },
    downloadCSVFileFailed: (state) => {
      state.isDownloading = false
    },
    getBankList: (state) => {
      state.isBankListLoading = true
    },
    getBankListSuccess: (
      state,
      { payload }: { payload: { bankList: Bank[] } },
    ) => {
      state.bankList = payload.bankList
      state.isBankListLoading = false
    },
    getBankListFailed: (state) => {
      state.isBankListLoading = false
    },

    getPaymentMethods: (state) => {
      state.isPaymentMethodsLoading = true
    },
    getPaymentMethodsSuccess: (
      state,
      { payload }: { payload: { paymentMethods: [] } },
    ) => {
      state.paymentMethods = payload.paymentMethods
      state.isPaymentMethodsLoading = false
    },
    getPaymentMethodsFailed: (state) => {
      state.isPaymentMethodsLoading = false
    },

    setFilters: (state, { payload }) => {
      state.filters = payload
      state.pagination.page = 0
    },
    setFiltersFromURL: (state, { payload }) => {
      state.filters = payload
    },
    resetFilters: (state) => {
      state.filters = initialFilterState
      state.pagination = initialPaginationState
    },
    setPagination: (state, { payload }) => {
      state.pagination = payload
    },
    getProcessSchedule: (state) => {
      state.isProcessScheduleLoading = true
    },
    initiateProcessSchedule: (state) => {
      state.isProcessScheduleLoading = true
    },
    getProcessScheduleSuccess: (
      state,
      {
        payload,
      }: {
        payload: {
          processScheduleData: ProcessSchedule
        }
      },
    ) => {
      state.processScheduleData = payload.processScheduleData
      state.isProcessScheduleLoading = false
    },
    getProcessScheduleFailed: (state) => {
      state.isProcessScheduleLoading = false
    },
    setFilterCount: (state) => {
      state.filterCount = Object.keys(state.filters ?? {}).filter(
        (key: string) => {
          const filterKeys = [
            "dateSelector",
            "accountId",
            "transactionType",
            "paymentMethod",
            "operator",
            "isMarked",
            "entityType",
          ]
          if (key === "accountId" && Array.isArray(state.filters.accountId)) {
            return (state.filters.accountId as string[]).some(
              (id: string) => !initialFilterState.accountId.includes(id),
            )
          }
          return (
            filterKeys.includes(key) &&
            state.filters[key as keyof FilterType] !==
              initialFilterState[key as keyof FilterType]
          )
        },
      ).length
    },
  },
})

export const {
  getTransactionList,
  getTransactionListSuccess,
  getTransactionListFailed,
  uploadCSVFile,
  uploadCSVFileSuccess,
  uploadCSVFileFailed,
  downloadCSVFile,
  downloadCSVFileSuccess,
  downloadCSVFileFailed,
  getBankList,
  getBankListSuccess,
  getBankListFailed,
  getPaymentMethods,
  getPaymentMethodsSuccess,
  getPaymentMethodsFailed,
  setFilters,
  setFiltersFromURL,
  resetFilters,
  setPagination,
  getProcessSchedule,
  initiateProcessSchedule,
  getProcessScheduleSuccess,
  getProcessScheduleFailed,
  setFilterCount,
} = transactionsSlice.actions

export const selectTransactions = (state: RootState) => state.transactions

export const selectSortModel = (state: RootState) => {
  if (state.transactions.filters.sortBy === "none") {
    return []
  } else {
    return [
      {
        field: state.transactions.filters.sortBy,
        sort: state.transactions.filters.orderBy,
      },
    ]
  }
}

export default transactionsSlice.reducer
