import {
  Chip,
  IconButton,
  Pagination,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material"
import ElevatedCard, {
  HoveredElevatedCard,
} from "../../components/ElevatedCard/ElevatedCard"
import {
  AllocatedDate,
  BookingId,
  GiftDayPassIcon,
  GSTChip,
  PaymentStatusChip,
  PersonName,
} from "./styles"
import PageHeader from "../../components/PageHeader/PageHeader"
import { useEffect, useState } from "react"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import {
  fetchBookingList,
  resetFilters,
  selectBookingHistory,
  setFilters,
  setPage,
} from "../../slices/bookingHistorySlice"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { USERS } from "../../constants/path"
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import DownloadIcon from "@mui/icons-material/Download"
import userCheck from "../../utils/userCheck"
import BookingHistoryFilter from "../../components/BookingHistoryFilter/BookingHistoryFilter"
import queryString from "query-string"
import { PaymentStatus, PaymentStatusColors } from "../../constants/data"
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter"

// Extend dayjs with the UTC plugin
dayjs.extend(utc)

const BookingHistory = () => {
  const navigate = useNavigate()
  const location = useLocation()
  // booking history store data and typed dispatch function
  const { bookingList, isLoading, pageInfo, filters } =
    useAppSelector(selectBookingHistory)
  const dispatch = useAppDispatch()
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))
  const { userId } = useParams()
  const [loadedFromUrl, setLoadedFromUrl] = useState(false)

  const constructApiRequestParams = (newPageNoValue: number) => {
    return {
      ...filters,
      ...pageInfo,
      type: filters.type || [],
      pageNo: newPageNoValue,
      startDate: dayjs(filters.startDate).format("YYYY-MM-DD"),
      endDate: dayjs(filters.endDate).format("YYYY-MM-DD"),
      center: filters.center !== "All" ? filters.center : undefined,
      bookingRef:
        filters.bookingRef !== "none" ? filters.bookingRef : undefined,
      userId: userId,
    }
  }

  useEffect(() => {
    // URL Parameters to Redux State
    const params = queryString.parse(location.search)
    if (Object.keys(params).length !== 0) {
      // URL has filter parameters
      if (!Array.isArray(params.pageNo)) {
        const pageNumber = parseInt(params.pageNo || "1")
        dispatch(setPage(pageNumber))
      }
      const updatedFilters = { ...filters, ...params }
      // Convert string values to arrays if necessary
      if (updatedFilters.type && !Array.isArray(updatedFilters.type)) {
        updatedFilters.type = [updatedFilters.type]
      }
      if (
        updatedFilters.paymentStatus &&
        !Array.isArray(updatedFilters.paymentStatus)
      ) {
        updatedFilters.paymentStatus = [updatedFilters.paymentStatus]
      }
      dispatch(setFilters(updatedFilters))
      setLoadedFromUrl(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Reset page number to 1 when filters are changed
  useEffect(() => {
    if (loadedFromUrl) {
      dispatch(setPage(1))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  useEffect(() => {
    // set filters to URL for sharing purpose
    const params = queryString.stringify({
      ...filters,
      bookingCode:
        filters.bookingCode && filters.bookingCode.length === 8
          ? filters.bookingCode
          : undefined,
      pageNo: pageInfo.pageNo,
    })
    navigate({ search: params })
    dispatch(fetchBookingList(constructApiRequestParams(pageInfo.pageNo)))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, filters, navigate, pageInfo.pageNo])

  // reset filter handler
  const resetFilterHandler = () => {
    dispatch(resetFilters())
  }

  //handle refresh
  const handleRefresh = () => {
    dispatch(fetchBookingList(constructApiRequestParams(1)))
  }

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    dispatch(setPage(value))
  }

  return (
    <>
      {!userId && <PageHeader title="Booking History" />}

      <BookingHistoryFilter
        handleRefresh={handleRefresh}
        isSmallScreen={isSmallScreen}
        resetFilterHandler={resetFilterHandler}
      />

      {isLoading ? (
        <>
          {[1, 2, 3].map((item) => (
            <ElevatedCard
              style={{
                width: userId ? "95%" : isSmallScreen ? "84%" : "600px",
              }}
              key={item}
            >
              <Skeleton variant="rectangular" height={100} />
            </ElevatedCard>
          ))}
        </>
      ) : (
        <>
          {bookingList.map((item, index) => (
            <HoveredElevatedCard
              style={{
                width: userId ? "95%" : isSmallScreen ? "84%" : "600px",
              }}
              onClick={() => {
                navigate(`details/${item.id}`, {
                  state: {
                    title: "Booking History Details",
                    prevPage: "Booking History",
                    path: location.pathname,
                    item,
                  },
                })
              }}
              key={index}
            >
              <Stack gap="4px">
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="h6">
                    {item?.center_details?.name ||
                      item?.meeting_room_details?.name}
                  </Typography>
                  <div>
                    {item?.gst_detail_id && (
                      <GSTChip color="primary" label="GST" size="small" />
                    )}
                    {item?.invoice_url && (
                      <IconButton
                        sx={{ mr: "4px" }}
                        onClick={(e) => {
                          e.stopPropagation()
                          window.open(item.invoice_url)
                        }}
                      >
                        <DownloadIcon color="primary" />
                      </IconButton>
                    )}
                    <Chip
                      color="primary"
                      label={
                        item?.daypass_type === "daypass"
                          ? "Daypass"
                          : "Bulk Daypass"
                      }
                    />
                  </div>
                </Stack>

                <Stack
                  direction={isSmallScreen ? "column" : "row"}
                  justifyContent={isSmallScreen ? "start" : "space-between"}
                  alignItems={isSmallScreen ? "start" : "center"}
                >
                  <PersonName
                    onClick={(e) => {
                      e.stopPropagation()
                      navigate(`${USERS}/${item.user_details.id}/profile`, {
                        state: {
                          title: "User Details",
                          prevPage: "Booking History",
                          path: location.pathname,
                        },
                      })
                    }}
                  >
                    {userCheck(
                      item?.user_details?.first_name &&
                        item?.user_details?.last_name
                        ? `${item.user_details.first_name} ${item.user_details.last_name} (${item.user_details.mobile})`
                        : "",
                      item.user_details.mobile,
                    )}
                  </PersonName>
                  {item.payment_status && (
                    <PaymentStatusChip
                      size="small"
                      color={
                        PaymentStatusColors[
                          item.payment_status as PaymentStatus
                        ]
                      }
                      label={capitalizeFirstLetter(item.payment_status)}
                    />
                  )}
                </Stack>

                <AllocatedDate>
                  Booking date:{" "}
                  {dayjs(item.booking_date).format(" dddd, D MMMM YYYY")}
                </AllocatedDate>
                <Stack
                  direction={isSmallScreen ? "column" : "row"}
                  justifyContent={isSmallScreen ? "start" : "space-between"}
                  alignItems={isSmallScreen ? "start" : "center"}
                >
                  <AllocatedDate>
                    Created at:{" "}
                    {dayjs
                      .utc(item.day_passes_data.created_at)
                      .utcOffset("+05:30")
                      .format("hh:mm A, dddd, D MMMM YYYY")}
                  </AllocatedDate>
                  <Stack direction="row" alignItems="center" gap={2}>
                    {item.created_by !== null &&
                      item.created_by !== item.user_details.id && (
                        <GiftDayPassIcon />
                      )}
                    <BookingId>#{item?.booking_code}</BookingId>
                  </Stack>
                </Stack>
              </Stack>
            </HoveredElevatedCard>
          ))}
          <ElevatedCard
            style={{
              width: isSmallScreen ? "80%" : userId ? "95%" : "600px",
              textAlign: "center",
            }}
          >
            {!isLoading && !bookingList.length && (
              <span>No Bookings found</span>
            )}
            {bookingList.length ? (
              <div>
                <Pagination
                  sx={{ width: "fit-content", margin: "auto" }}
                  count={pageInfo.totalPages}
                  page={pageInfo.pageNo}
                  onChange={handlePageChange}
                />
              </div>
            ) : null}
          </ElevatedCard>
        </>
      )}
    </>
  )
}

export default BookingHistory
