import Dialog from "@mui/material/Dialog"
import { CloseIconButton, DrawerContent } from "./styles"
import { Autocomplete, Button, TextField, Typography } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { useState } from "react"
import { Bank } from "../../models/transactions.model"
import { useAppDispatch } from "../../app/hooks"
import { uploadCSVFile } from "../../slices/transactionsSlice"

type UploadPopupProps = {
  open: boolean
  onClose: () => void
  bankList: Bank[]
}

const UploadPopup = ({ open, onClose, bankList }: UploadPopupProps) => {
  const dispatch = useAppDispatch()
  const [selectedBank, setSelectedBank] = useState<Bank | null>(null)

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files && event.target.files[0]
    if (selectedFile) {
      dispatch(
        uploadCSVFile({
          file: selectedFile,
          accountId: selectedBank?.id || "",
        }),
      )
      onClose()
    }
  }
  return (
    <Dialog open={open} onClose={onClose}>
      <DrawerContent sx={{ width: "300px" }}>
        <CloseIconButton onClick={onClose}>
          <CloseIcon />
        </CloseIconButton>
        <Typography variant="h5" sx={{ mb: 2 }}>
          Upload Files
        </Typography>
        <Autocomplete
          fullWidth
          id="select-banks-account"
          options={bankList}
          value={selectedBank}
          onChange={(_, newValue) => {
            setSelectedBank(newValue)
          }}
          getOptionLabel={(option: Bank) => option.accountName}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Account"
              variant="outlined"
              size="small"
            />
          )}
        />
        <div>
          <input
            accept={selectedBank?.allowedFileType || ".csv, .xls, .xlsx"}
            style={{ display: "none" }}
            id="contained-button-file"
            type="file"
            onChange={handleFileChange}
            onClick={(
              event: React.MouseEvent<HTMLInputElement, MouseEvent>,
            ) => {
              const element = event.target as HTMLInputElement
              element.value = ""
            }}
          />
          <label htmlFor="contained-button-file">
            <Button
              fullWidth
              variant="contained"
              component="span"
              disabled={!selectedBank || selectedBank.id === ""}
            >
              choose Files
            </Button>
          </label>
        </div>
      </DrawerContent>
    </Dialog>
  )
}

export default UploadPopup
