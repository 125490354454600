export interface CustomerPendingInvoicesResponse {
  invoices: InvoiceResponse[]
}

export interface InvoiceResponse {
  id: string
  invoice_id: string
  customer_id: string
  customer_name: string
  company_name: string
  email: string
  status: string
  number: string
  date: string
  due_date: string
  total: number
  balance: number
  source: string
  tds_2_percent: string
  tds_3_percent: string
  tds_10_percent: string
  tds_already_deducted: string
  is_tds_fully_deducted: boolean
}

export class Invoice {
  zohoInvoiceId = ""
  balance = Infinity
  companyName = ""
  customerId = ""
  customerName = ""
  date = ""
  dueDate = ""
  email = ""
  invoiceId = ""
  invoiceNumber = ""
  status = ""
  total = 0
  source = ""
  tds2Percent = 0
  tds3Percent = 0
  tds10Percent = 0
  tdsAlreadyDeducted = 0
  isTdsFullyDeducted = false

  getTdsPercent(tds: number) {
    const key = `tds${tds}Percent` as keyof Invoice
    return this[key] as number
  }

  canTdsDeducted(tdsArr: number[]) {
    if (this.isTdsFullyDeducted) {
      return false
    }

    for (const tds of tdsArr) {
      const key = `tds${tds}Percent` as keyof Invoice
      if (
        typeof this[key] === "number" &&
        this[key] > this.tdsAlreadyDeducted
      ) {
        return true
      }
    }

    return false
  }

  constructor(invoiceDetails?: InvoiceResponse) {
    if (invoiceDetails) {
      this.invoiceId = invoiceDetails?.id
      this.balance = invoiceDetails?.balance
      this.customerName = invoiceDetails?.customer_name
      this.companyName = invoiceDetails?.company_name
      this.customerId = invoiceDetails?.customer_id
      this.date = invoiceDetails?.date
      this.dueDate = invoiceDetails?.due_date
      this.email = invoiceDetails?.email
      this.zohoInvoiceId = invoiceDetails?.invoice_id
      this.invoiceNumber = invoiceDetails?.number
      this.status = invoiceDetails?.status
      this.total = invoiceDetails?.total
      this.source = invoiceDetails?.source
      this.tds2Percent = invoiceDetails?.tds_2_percent
        ? Number.parseFloat(invoiceDetails.tds_2_percent)
        : 0
      this.tds3Percent = invoiceDetails?.tds_3_percent
        ? Number.parseFloat(invoiceDetails.tds_3_percent)
        : 0
      this.tds10Percent = invoiceDetails?.tds_10_percent
        ? Number.parseFloat(invoiceDetails.tds_10_percent)
        : 0
      this.tdsAlreadyDeducted = invoiceDetails?.tds_already_deducted
        ? Number.parseFloat(invoiceDetails.tds_already_deducted)
        : 0
      this.isTdsFullyDeducted = invoiceDetails?.is_tds_fully_deducted
    }
  }
}
