import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
  ReconciliationState,
  ReconciliationDTO,
} from "../models/reconciliation.model"
import { Customer } from "../models/customer.model"
import { Invoice } from "../models/invoice.model"
import { RootState } from "../app/store"

export const reconciliationInitialState: ReconciliationState = {
  customers: [],
  invoices: [],
  loading: false,
  selectedCustomer: null,
  submitReconciliationSuccess: false,
  transactionActionSuccess: false,
  invoicesFetchError: null,
}

const reconciliationSlice = createSlice({
  name: "reconciliation",
  initialState: reconciliationInitialState,
  reducers: {
    setSelectedCustomer: (state, action: PayloadAction<Customer | null>) => {
      state.selectedCustomer = action.payload
    },
    clearReconciliation: (state) => {
      state.customers = []
      state.invoices = []
      state.selectedCustomer = null
      state.submitReconciliationSuccess = false
    },
    // action creators for saga flow
    searchCustomersRequest: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{
        searchTerm: string
        transactionSource: string
      }>,
    ) => {
      state.loading = true
    },
    searchCustomersSuccess: (state, action: PayloadAction<Customer[]>) => {
      state.loading = false
      state.customers = action.payload
    },
    searchCustomersFailure: (state) => {
      state.loading = false
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchInvoicesRequest: (state, action: PayloadAction<string>) => {
      state.loading = true
      state.invoices = []
      state.invoicesFetchError = null
    },
    fetchInvoicesSuccess: (state, action: PayloadAction<Invoice[]>) => {
      state.loading = false
      state.invoices = action.payload
    },
    fetchInvoicesFailure: (state, action: PayloadAction<string | null>) => {
      state.loading = false
      state.invoicesFetchError = action.payload
    },

    submitReconciliationRequest: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<ReconciliationDTO>,
    ) => {
      state.loading = true
    },
    submitReconciliationSuccess: (state) => {
      state.loading = false
      state.submitReconciliationSuccess = true
      state.selectedCustomer = null
      state.invoices = []
    },
    submitReconciliationFailure: (state) => {
      state.loading = false
    },
    unMatchTransactionRequest: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<string>,
    ) => {
      state.loading = true
    },
    unMatchTransactionSuccess: (state) => {
      state.loading = false
      state.transactionActionSuccess = true
      state.selectedCustomer = null
      state.invoices = []
    },
    unMatchTransactionFailure: (state) => {
      state.loading = false
    },
    unCategoriseTransactionRequest: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<string>,
    ) => {
      state.loading = true
    },
    unCategoriseTransactionSuccess: (state) => {
      state.loading = false
      state.transactionActionSuccess = true
      state.invoices = []
    },
    unCategoriseTransactionFailure: (state) => {
      state.loading = false
    },
    resetTransactionActionSuccess: (state) => {
      state.transactionActionSuccess = false
    },
  },
})

export const {
  setSelectedCustomer,
  clearReconciliation,
  searchCustomersRequest,
  searchCustomersSuccess,
  searchCustomersFailure,
  fetchInvoicesRequest,
  fetchInvoicesSuccess,
  fetchInvoicesFailure,
  submitReconciliationRequest,
  submitReconciliationSuccess,
  submitReconciliationFailure,
  unMatchTransactionRequest,
  unMatchTransactionSuccess,
  unMatchTransactionFailure,
  unCategoriseTransactionRequest,
  unCategoriseTransactionSuccess,
  unCategoriseTransactionFailure,
  resetTransactionActionSuccess,
} = reconciliationSlice.actions

export const selectReconciliation = (state: RootState) => state.reconciliation

export default reconciliationSlice.reducer
