import styled from "@emotion/styled"
import { Card, Chip } from "@mui/material"

const mdScreen = "@media (max-width: 1145px)"
const card = "@media (max-width: 900px)"

export const Container = styled("div")`
  margin: 20px;
  padding: 10px 20px;
`

export const FlexDiv = styled("div")`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 0 auto;
`

export const ContainerCard = styled(Card)`
  margin-bottom: 15px;
  background-color: #fff;
  padding: 10px 20px;
`

export const ContainerCardUser = styled(Card)`
  margin-top: 15px;
  margin-bottom: 15px;
  background-color: #fff;
  padding: 10px 20px;
`

export const EllipsisDiv = styled("div")`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 10px;
`
export const ImportantInfoCard = styled(Card)`
  background-color: #fff;
  padding: 10px 20px;
  margin: 10px 0px 0px 0px;
  max-width: 850px;
  width: 80%;
  display: flex;
  justify-self: center;
  & > .flex-user-info {
    flex-grow: 0;
  }
  & > .flex-booking-info {
    flex-grow: 3;
    padding: 0px 20px;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
  }

  ${card} {
    max-width: 400px;
    width: 100%;
    flex-direction: column;
    padding: 20px 0px;
  }
`

export const PaymentStatusChip = styled(Chip)`
  color: ${({ theme }: { theme: any }) => theme?.palette?.common?.white};
`

export const ContainerCardBooking = styled(Card)`
  margin-top: 15px;
  margin-bottom: 15px;
  background-color: #fff;
  padding: 10px 20px;
  min-height: 704px;
  margin-right: 10px;
  ${mdScreen} {
    margin-right: 0px;
    min-height: auto;
  }
`

export const ContainerCardPayments = styled(Card)`
  background-color: #fff;
  padding: 10px 20px;
  width: 100%;
  max-width: 850px;
  margin: 15px auto;
  ${mdScreen} {
    max-width: 400px;
  }
`

export const ContainerCardCenter = styled(Card)`
  margin-top: 15px;
  margin-bottom: 15px;
  background-color: #fff;
  padding: 10px 20px;
  max-width: 440px;
  min-height: 345px;
`

export const ContainerCardHolder = styled("div")`
  max-width: 440px;
`

export const ContainerCardPay = styled(Card)`
  margin-top: 15px;
  margin-bottom: 15px;
  background-color: #fff;
  padding: 10px 20px;
  width: 400px;
  margin: 10px 15px;
  box-shadow: none;
  box-shadow: none;
  border: 1px solid #dcd6d0;
  width: 100%;
`

export const StyledHeading = styled("h2")`
  text-align: center;
`

export const InnerStyledHeading = styled("h4")`
  text-align: center;
`

export const DetailsContainer = styled("div")`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`

export const HoldingContainer = styled("div")`
  max-width: 400px;
  width: 400px;
`

export const HoldingContainerCenter = styled("div")`
  max-width: 400px;
  width: 400px;
  text-align: center;
`

export const HoldingContainerUserImage = styled("div")`
  display: content;
  min-height: 130px;
  &:hover {
    cursor: pointer;
  }
`
export const Holder = styled("div")`
  width: 400px;
`
export const StyledImg = styled("img")`
  min-height: 100px;
  height: 100px;
  width: 350px;
  min-width: 350px;
  border-radius: 5px;
  object-fit: cover;
  margin-bottom: 10px;
  &:hover {
    box-shadow: rgb(71 88 114 / 20%) 0px 4px 12px;
    transform: translateY(-2px);
    cursor: pointer;
  }
  background-color: gray;
`

export const ImgSkeleton = styled("div")`
  min-height: 100px;
  height: 100px;
  width: 350px;
  min-width: 350px;
  border-radius: 5px;
  object-fit: cover;
  margin-bottom: 10px;
  margin: 0 auto;
`

export const DetailsDiv = styled("div")`
  max-width: 400px;
  width: 400px;
`

export const ParaTag = styled("div")`
  margin-bottom: 10px;
  font-size: 16px;
`

export const ParaTagClick = styled("span")`
  margin-bottom: 10px;
  font-size: 16px;
  cursor: pointer;
  text-decoration: underline;
`

export const BoldSpan = styled("span")`
  font-weight: bold;
`

export const ButtonContainer = styled("div")`
  display: flex;
  justify-content: center;
  margin-top: 15px;
  gap: 10px;
  flex-wrap: wrap;
`
