import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { Autocomplete } from "@mui/material"
import { useEffect, useState } from "react"
import { modifyCenter } from "../../slices/bookingHistorySlice"
import {
  fetchDaypassEnabledCenters,
  selectDaypassBooking,
} from "../../slices/daypassBookingSlice"

export default function ChangeCenterModal({
  open,
  handleClose,
  centerId,
  basePrice,
  bookingId,
}: {
  open: boolean
  handleClose: () => void
  centerId: string
  basePrice: number
  bookingId: string
}) {
  const dispatch = useAppDispatch()
  const { daypassEnabledCenters } = useAppSelector(selectDaypassBooking)
  const [selectedCenterId, setSelectedCenterId] = useState("")

  useEffect(() => {
    dispatch(fetchDaypassEnabledCenters())
  }, [dispatch])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="xs"
      keepMounted={false}
    >
      <DialogTitle>Update Center</DialogTitle>
      <DialogContent>
        <Autocomplete
          sx={{ paddingBlock: "10px" }}
          fullWidth
          id="center-name"
          options={daypassEnabledCenters}
          getOptionLabel={(option) =>
            `(₹${option.day_pass_price || 0}) ${option.name}`
          }
          getOptionDisabled={(option) =>
            option.id === centerId || option.day_pass_price > basePrice
          }
          value={
            daypassEnabledCenters.find(
              (center) => center.id === selectedCenterId,
            ) || null
          }
          onChange={(_, newValue) => setSelectedCenterId(newValue?.id || "")}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Center Name"
              variant="outlined"
              size="small"
              required
              fullWidth
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined" color="secondary">
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            dispatch(modifyCenter({ bookingId, centerId: selectedCenterId }))
            handleClose()
          }}
          disabled={selectedCenterId === ""}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}
