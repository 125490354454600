import axiosConfig from "../app/axiosConfig"
import {
  BOOKINGS_API,
  BOOKINGS_API_BY_ID,
  BOOKINGS_API_BY_ID_MODIFY_CENTER,
  BOOKINGS_API_BY_ID_MODIFY_MOBILE,
  BOOKINGS_MEETING_ROOMS_API,
  BOOKINGS_UPDATE_ORDER_DETAILS,
  BOOKINGS_UPDATE_ORDER_MARK_ACTIVE,
  USER_DETAILS_BY_ID_API,
} from "../constants/apis"
import {
  fetchBookingDetailsPayloadType,
  fetchBookingListPayloadType,
  fetchDaypassBookingByIdPayloadType,
  modifyCenterPayloadType,
  modifyMobilePayloadType,
} from "../slices/bookingHistorySlice"
import { computeRoute } from "../utils/compute-route"

export const fetchDaypassBookingService = ({
  payload,
}: fetchBookingListPayloadType) =>
  axiosConfig.get(BOOKINGS_API, {
    params: {
      start_date: payload.startDate,
      end_date: payload.endDate,
      limit: payload.pageSize,
      page: payload.pageNo,
      type: payload.type,
      center_id: payload.center,
      is_active: payload.isActive,
      payment_status: payload.paymentStatus,
      booking_ref: payload.bookingRef,
      user_id: payload.userId,
      ...(payload.invoiceStatus !== "none" && {
        invoice_status: payload.invoiceStatus,
      }),
      ...(payload.bookingCode && payload.bookingCode.length === 8
        ? { booking_code: payload.bookingCode }
        : {}),
    },
  })

export const fetchDaypassBookingByIdService = ({
  payload,
}: fetchDaypassBookingByIdPayloadType) =>
  axiosConfig.get(computeRoute(BOOKINGS_API_BY_ID, { ...payload }))

export const fetchMeetingRoomBookingService = () =>
  axiosConfig.get(BOOKINGS_MEETING_ROOMS_API)

export const fetchBookingDetailsService = ({
  payload,
}: fetchBookingDetailsPayloadType) =>
  axiosConfig.get(computeRoute(USER_DETAILS_BY_ID_API, { ...payload }))

export const updateOrderDetailsService = ({
  payload,
}: fetchDaypassBookingByIdPayloadType) =>
  axiosConfig.put(computeRoute(BOOKINGS_UPDATE_ORDER_DETAILS, { ...payload }))

export const updateOrderDetailsMarkAsActiveService = ({
  payload,
}: fetchDaypassBookingByIdPayloadType) =>
  axiosConfig.put(
    computeRoute(BOOKINGS_UPDATE_ORDER_MARK_ACTIVE, { ...payload }),
  )

export const modifyCenterService = ({ payload }: modifyCenterPayloadType) =>
  axiosConfig.put(
    computeRoute(BOOKINGS_API_BY_ID_MODIFY_CENTER, {
      bookingId: payload.bookingId,
    }),
    { center_id: payload.centerId },
  )

export const modifyMobileService = ({ payload }: modifyMobilePayloadType) =>
  axiosConfig.put(
    computeRoute(BOOKINGS_API_BY_ID_MODIFY_MOBILE, {
      bookingId: payload.bookingId,
    }),
    { mobile: payload.mobile },
  )
