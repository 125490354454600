export interface CustomerSearchResponse {
  contacts: CustomerResponse[]
}

export interface CustomerResponse {
  id: string
  contact_id: string
  name: string
  default_tds: number
  possible_tds: number[]
}

export class Customer {
  id: string
  contactId: string
  name: string
  defaultTds: number
  possibleTds: number[]
  constructor(customerResponse?: CustomerResponse) {
    this.id = customerResponse?.id ?? ""
    this.contactId = customerResponse?.contact_id ?? ""
    this.name = customerResponse?.name ?? ""
    this.defaultTds = customerResponse?.default_tds ?? 0
    this.possibleTds = customerResponse?.possible_tds ?? []
  }
}
