import { takeLatest, put, call } from "redux-saga/effects"
import {
  fetchCentersService,
  addCenterService,
  updateCenterService,
  fetchUsersByNameService,
  fetchCenterDetailsService,
  fetchCenterClusterDetailsService,
} from "../services/centersService"
import {
  fetchCenters,
  fetchCentersFailed,
  fetchCentersSuccess,
  addCenter,
  addCenterSuccess,
  addCenterFailed,
  addCenterPayloadType,
  updateCenter,
  updateCenterSuccess,
  updateCenterFailed,
  updateCenterPayloadType,
  fetchUsersByName,
  fetchUsersByNameFailed,
  fetchUsersByNameSuccess,
  fetchCenterDetails,
  fetchCenterDetailsFailed,
  fetchCenterDetailsSuccess,
  centerDetailsPayloadType,
  fetchUsersByNamePayloadType,
  fetchCenterClusterDetailsSuccess,
  fetchCenterClusterDetailsFailed,
  fetchCenterClusterDetails,
} from "../slices/centersSlice"
import { showToast } from "../slices/toastSlice"

export function* fetchCentersSaga() {
  try {
    const { data } = yield call(fetchCentersService)
    yield put(
      fetchCentersSuccess({
        centers: data?.data || [],
      }),
    )
  } catch (err) {
    yield put(fetchCentersFailed())
  }
}

export function* addCenterSaga(action: addCenterPayloadType) {
  try {
    const { data } = yield call(addCenterService, action)
    yield put(addCenterSuccess({ centerDeatils: data.data }))
    yield put(
      showToast({
        toastType: "success",
        msg: "Center Added Successfully",
      }),
    )
  } catch (err) {
    yield put(addCenterFailed())
  }
}

export function* updateCenterSaga(action: updateCenterPayloadType) {
  try {
    const { data } = yield call(updateCenterService, action)
    yield put(updateCenterSuccess({ centerDeatils: data.data }))
    yield put(
      showToast({
        toastType: "success",
        msg: "Center Updated Successfully",
      }),
    )
  } catch (err) {
    yield put(updateCenterFailed())
  }
}

export function* fetchUsersByNameSaga(action: fetchUsersByNamePayloadType) {
  try {
    const { data } = yield call(fetchUsersByNameService, action)
    yield put(
      fetchUsersByNameSuccess({
        users: data.data || [],
      }),
    )
  } catch (err) {
    yield put(fetchUsersByNameFailed())
  }
}

export function* fetchCenterDetailsSaga(action: centerDetailsPayloadType) {
  try {
    const { data } = yield call(fetchCenterDetailsService, action)
    yield put(fetchCenterDetailsSuccess({ centerDeatils: data.data }))
  } catch (err) {
    yield put(fetchCenterDetailsFailed())
  }
}

export function* fetchCenterClusterDetailsSaga() {
  try {
    const { data } = yield call(fetchCenterClusterDetailsService)
    yield put(
      fetchCenterClusterDetailsSuccess({ centerClusterDetails: data.result }),
    )
  } catch (err) {
    yield put(fetchCenterClusterDetailsFailed())
  }
}

export default function* centerSaga() {
  yield takeLatest(fetchCenters, fetchCentersSaga)
  yield takeLatest(addCenter, addCenterSaga)
  yield takeLatest(updateCenter, updateCenterSaga)
  yield takeLatest(fetchUsersByName, fetchUsersByNameSaga)
  yield takeLatest(fetchCenterDetails, fetchCenterDetailsSaga)
  yield takeLatest(fetchCenterClusterDetails, fetchCenterClusterDetailsSaga)
}
