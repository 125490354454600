import { Box, Button } from "@mui/material"
import { fetchCenters, selectCenters } from "../../slices/centersSlice"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { useNavigate } from "react-router-dom"
import { CENTERS } from "../../constants/path"
import { useEffect, useState } from "react"
import PageHeader from "../../components/PageHeader/PageHeader"
import { selectLogin } from "../../slices/loginSlice"
import { UserRole } from "../../constants/data"
import Table from "../../components/Table/Table"
import { PaginationType } from "../../slices/auditLogsSlice"
import { GridColDef } from "@mui/x-data-grid"

const Centers = () => {
  const centersData = useAppSelector(selectCenters)
  const { role } = useAppSelector(selectLogin)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  })

  useEffect(() => {
    dispatch(fetchCenters())
  }, [dispatch])

  const rows = centersData.centers.map((center) => ({
    id: center.id,
    name: center.name,
    isDayPassEnable: center.is_day_pass_enabled,
    dayPassPrice: center.day_pass_price,
    centerContactNumber: center.contact_person_phone,
  }))

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Center Name",
      width: 500,
      renderCell: (row) => <b>{row?.value}</b>,
      type: "string",
    },
    {
      field: "isDayPassEnable",
      headerName: "Daypass Available",
      width: 200,
      type: "boolean",
    },
    {
      field: "dayPassPrice",
      headerName: "Daypass Price",
      width: 150,
      type: "number",
      renderCell: (row) => <span>₹{row?.value}</span>,
    },
    { field: "centerContactNumber", headerName: "Contact Number", width: 200 },
    {
      field: "action",
      headerName: "Action",
      width: 200,
      type: "actions",
      renderCell: ({ row }) => {
        return (
          <Button
            size="small"
            variant="text"
            color="info"
            onClick={() => navigate(`${CENTERS}/details/${row.id}`)}
          >
            View Details
          </Button>
        )
      },
    },
  ]

  const paginationHandler = (page: PaginationType) => {
    setPaginationModel(page)
  }

  return (
    <>
      <PageHeader
        title="Centers"
        actions={
          <>
            <div>
              {role === UserRole.ADMIN && !centersData.isCenterLoading && (
                <Button
                  variant="contained"
                  onClick={() => navigate(`${CENTERS}/add`)}
                >
                  Add New Center
                </Button>
              )}
            </div>
          </>
        }
      />
      <Box m="auto" width="98%" mt={2}>
        <Table
          rows={rows}
          columns={columns}
          loading={centersData.isCenterLoading}
          rowCount={centersData.centers.length}
          paginationModel={paginationModel}
          onPaginationModelChange={paginationHandler}
          paginationMode="client"
          pageSizeOptions={[25, 50, 100]}
          disableColumnFilter
          height="calc(100vh - 150px)"
        />
      </Box>
    </>
  )
}

export default Centers
